<script lang="ts" setup>
import { ref, watch } from 'vue';

import { useInfiniteScroll } from '@vueuse/core';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
import PagesTypeTab from '../pick-link/PagesTypeTab.vue';
import type { TabType } from '../../type/pickLink';

type List = { title?: string; handle?: string; id?: string };
// props & emit
type Props = {
  value: { link?: string; target?: string; title?: string };
  list?: List[];
  loading?: boolean;
  type?: 'Product' | 'Collection' | 'Blog' | 'Article';
  targets: { label: string; value: string }[];
  instant?: boolean;
  shopDomain?: string;
  inputSearchComponent?: Object;
  isShowBanner?: boolean;
  getCurrentTab: TabType;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'showMore'): void;
  (e: 'refresh'): void;
  (e: 'onSearch', value?: string): void;
  (e: 'save', value?: { link: string; target: string; title: string; selectedTab: string }): void;
  (e: 'onSetTabSelected', tab?: TabType): void;
  (e: 'close-banner'): void;
  (e: 'create-popup'): void;
}>();
// const

const val = ref(props.value);
const isDisableSave = ref(false);

watch(
  () => props.value,
  (nV, oV) => {
    if (nV !== oV) {
      val.value = nV;
    }
  },
);

const productInfinityScroll = ref<HTMLElement | null>(null);
const customUrl = ref(val.value.link ?? '/');
const customTitle = ref(val.value.title ?? '');
const target = props.targets.find((v) => v.value === val.value.target);
const selectedTargets = ref<{ label: string; value: string }>(target ?? { label: 'Current Tab', value: '_self' });
const selectedTab = ref<string>(props.getCurrentTab || 'custom');

const categories = props.instant
  ? {
      custom: PagesTypeTab,
      pages: PagesTypeTab,
      scrollToList: PagesTypeTab,
      emails: PagesTypeTab,
      phone: PagesTypeTab,
      popup: PagesTypeTab,
    }
  : {
      custom: PagesTypeTab,
      pages: PagesTypeTab,
      collections: PagesTypeTab,
      products: PagesTypeTab,
      articles: PagesTypeTab,
      popup: PagesTypeTab,
      scrollToList: PagesTypeTab,
      emails: PagesTypeTab,
      phone: PagesTypeTab,
    };

// methods
useInfiniteScroll(
  productInfinityScroll,
  () => {
    // load more
    emit('showMore');
  },
  { distance: 100 },
);
const handleSelectedTab = (value?: string) => {
  if (value) {
    selectedTab.value = value;
    emit('onSetTabSelected', value as TabType);
  }
  onHandleDisable(false);
};
const close = () => {
  emit('close');
};
const setCustomTitle = (title: string) => {
  customTitle.value = title;
};
const setCustomLink = (val: string, isEmail?: boolean | undefined) => {
  try {
    if (isEmail || val.startsWith('tel:')) {
      customUrl.value = val;
      return;
    }
    customUrl.value = new URL(val).pathname;
  } catch (e) {
    customUrl.value = val;
  }
};
const onSearch = (value: string) => {
  emit('onSearch', value);
};
const clear = () => {
  customUrl.value = '';
};
const onChange = (value: string) => {
  customUrl.value = value;
};
const listTab = ['custom', 'pages', 'collections', 'products', 'articles', 'popup', 'scrollToList', 'emails', 'phone'];

const getTitleTab = (type: TabType) => {
  switch (type) {
    case 'pages':
      return 'Pages';
    case 'collections':
      return 'Collections';
    case 'products':
      return 'Products';
    case 'articles':
      return 'Blog posts';
    case 'scrollToList':
      return 'Scroll to';
    case 'emails':
      return 'Email';
    case 'popup':
      return 'Popup';
    case 'phone':
      return 'Phone';
    case 'custom':
      return 'Custom';
    default:
      return '';
  }
};

const onHandleDisable = (disable: boolean) => {
  isDisableSave.value = disable;
};
</script>
<template>
  <Teleport to="body">
    <g-modal
      :is-open="true"
      :show-btn-close="true"
      header-class="cursor-default border-none"
      modal-class=" lg:w-[600px] z-[50] rounded-medium max-h-[calc(100vh_-_72px)] h-full "
      container-class="left-[337px] top-[56px] max-h-[calc(100vh_-_72px)] rounded-[12px] "
      body-class="bg-light-400 overflow-y-visible"
      :show-line-bottom="false"
      :show-line-top="false"
      @ok="
        emit('save', { link: customUrl, target: selectedTargets.value, title: customTitle, selectedTab: selectedTab })
      "
      @close="close"
      @cancel="close">
      <template #title>
        <div>
          <div>Link picker</div>
        </div>
      </template>
      <template #default>
        <div class="flex max-h-[calc(100vh_-_192px)] flex-1 flex-col">
          <!-- body -->
          <div class="flex-1 overflow-hidden" data-test="setting-modal-pick-link-body">
            <div class="flex h-full flex-col overflow-hidden">
              <div class="flex w-full flex-1 flex-col overflow-hidden bg-white sm:px-0">
                <TabGroup :selected-index="listTab.indexOf(selectedTab)">
                  <TabList
                    class="border-light-400 mx-16 mb-16 flex rounded-xl border p-4"
                    :class="{
                      'justify-between': !instant,
                    }">
                    <Tab
                      v-for="category in Object.keys(categories)"
                      :key="category"
                      v-slot="{ selected }"
                      :data-test="`setting-modal-option-type-${category}`"
                      as="template">
                      <button
                        :class="[
                          'text-12 hover:bg-light-250 min-h-[28px] font-medium leading-5',
                          'rounded-[6px] px-8  focus:outline-none',
                          selected ? '   text-dark-400 !bg-underlay-light-blue' : ' text-text-light-300',
                        ]"
                        @click="handleSelectedTab(category)">
                        {{ getTitleTab(category as TabType) }}
                      </button>
                    </Tab>
                  </TabList>
                  <TabPanels data-test="setting-modal-page-listing" class="h-full flex-1">
                    <TabPanel
                      v-for="posts in Object.values(categories)"
                      :key="posts"
                      :class="[
                        ' bg-light-400 overflow-y-auto',
                        'ring-white ring-opacity-60 ring-offset-2 focus:outline-none',
                      ]">
                      <div class="bg-light-400 low:h-[440px] h-[552px]">
                        <div v-if="selectedTab === 'custom'" class="pb-16">
                          <div class="flex gap-16 px-16 pt-16">
                            <div class="flex-1">
                              <p class="text-12 text-dark-400 mb-4 font-medium leading-5">Custom URL</p>
                              <g-input
                                container-classes="h-[40px]"
                                classes="px-8 h-[40px] text-14 bg-light-100"
                                size="small"
                                placeholder="E.g.: gempages.net"
                                :value="customUrl"
                                @on-change="onChange">
                                ></g-input
                              >
                            </div>
                          </div>
                        </div>
                        <PagesTypeTab
                          :is="posts"
                          v-else
                          :shop-domain="shopDomain"
                          :is-show-banner="isShowBanner"
                          :type="(selectedTab as TabType)"
                          :list="list"
                          :custom-url="value.link"
                          :link="customUrl"
                          :is-instant="instant"
                          :loading="loading"
                          :input-search-component="inputSearchComponent"
                          @close-banner="emit('close-banner')"
                          @on-handle-disable="onHandleDisable"
                          @set-custom-title="setCustomTitle"
                          @set-custom-url="setCustomLink"
                          @show-more="emit('showMore')"
                          @create-popup="emit('create-popup')"
                          @on-search="onSearch" />
                      </div>
                    </TabPanel>
                  </TabPanels>
                </TabGroup>
              </div>
            </div>
          </div>
        </div>
      </template>
    </g-modal>
  </Teleport>
</template>
