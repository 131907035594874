<script lang="ts" setup>
import type { ProductOption, SelectedOption } from '../type/common';
import type { Product } from './modal/MultiProductModal.vue';
import MultiProductModal from './modal/MultiProductModal.vue';
import { computed, ref } from 'vue';
import { replaceImageToSize } from '../hook/useResizeImage';
import { GReorder } from '@gem/uikit';
import { isDefined } from '../utils/common';

type Props = {
  id?: string;
  value?: string[];
  productDisplay?: Product[];
  isLoading?: boolean;
  options?: ProductOption[];
  variants?: SelectedOption[];
  productList?: Product[];
  max?: number;
  connectStatus?: boolean;
  syncPercent?: number;
  pageType?: string;
  warningMessage?: string;
  inputSearchComponent?: Object;
  tag?: string;
  maxAllowSelected?: number;
};

type Item = {
  id?: string;
  title?: string;
  images?: string;
  handle?: string;
};

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'controlOnChange', controlId?: string | number, value?: any): void;
  (e: 'controlChange', controlId?: string | number, value?: Product[]): void;
  (e: 'controlFocus', controlId?: string | number, value?: any): void;
  (e: 'controlBlur', controlId?: string | number, value?: any): void;
  (e: 'controlOnSearch', controlId?: string | number, value?: any): void;
  (e: 'changeVariants', value?: any): void;
  (e: 'controlRefresh'): void;
  (e: 'connect-shopify'): void;
  (e: 'showMore', action?: 'fetch'): void;
  (e: 'openSaleFunnel'): void;
}>();

const productListVisibility = ref<boolean>(false);
const FIRST_VIEW_ITEM = 4;
const end = ref(FIRST_VIEW_ITEM);

const deleteItems = ref(0);

const closeModal = () => {
  productListVisibility.value = false;
};

const openModal = () => {
  productListVisibility.value = true;
  emit('showMore'); // trigger  for wrap know product list modal is open
};

const setValueSelected = (productList?: Product[]) => {
  emit('controlChange', props.id, productList || []);
};

const onSearch = (value?: string) => {
  emit('controlOnSearch', props.id, value);
};

const cloneList = computed(() => {
  return props.value?.map((id) => props.productDisplay?.find((el) => el.id === id)).filter(isDefined);
});

const deleteSingleItem = (id: string) => {
  const newProductList = cloneList.value?.filter((v) => v?.id !== id);
  emit('controlChange', props.id, newProductList);
  deleteItems.value++;
};

const next = () => {
  if (cloneList.value?.length ?? 0 <= end.value) {
    end.value = cloneList.value?.length ?? 0;
  }
};

const prev = () => {
  if ((cloneList.value?.length ?? 0 >= end.value) && end.value > FIRST_VIEW_ITEM) {
    end.value = FIRST_VIEW_ITEM;
  }
};

const list = computed(() => {
  if (props.value && props.value.length == 0) {
    return [];
  }
  if (cloneList.value?.length === 0) {
    return [];
  } else if (cloneList.value?.length ?? 0 > end.value) {
    return [...(cloneList.value?.slice(0, end.value) ?? [])].map((v) => ({
      id: v?.id,
      title: v?.title,
      image: v?.image,
      handle: v?.handle,
    }));
  } else return [];
});

function handleReorder(items: Item[], _from?: number, _to?: number) {
  if (items.length) {
    emit(
      'controlChange',
      props.id,
      cloneList.value?.map((v, index) => {
        if (index < end.value) {
          return items[index] as Product;
        }
        return v;
      }),
    );
  }
}

const openSaleFunnel = () => {
  emit('openSaleFunnel');
};

const isSalePage = computed(() => {
  return props.pageType === 'GP_FUNNEL_PAGE' && props.tag === 'Product';
});
</script>
<template>
  <div class="gemx-control">
    <div>
      <div class="flex flex-col gap-8">
        <div v-if="list && list.length" class="bg-dark-500 rounded-medium mx-[-7px] flex flex-col gap-4 p-8">
          <GReorder :items="list" @re-order="handleReorder">
            <template #default="{ item }">
              <div
                class="rounded-medium bg-dark-500 group-[.sortable-fallback]/sortable-item:bg-dark-500 group-[.sortable-ghost]/sortable-item:bg-dark-400 group-[&:not(.dragging)]/sortable:hover:bg-dark-400 group flex h-[48px] w-[248px] cursor-ns-resize items-center gap-8 p-8">
                <img
                  v-if="item?.image"
                  :alt="item?.title"
                  class="rounded-medium flex aspect-square h-32 w-32 shrink-0 overflow-hidden object-cover"
                  :src="replaceImageToSize(item?.image ?? '', '100x100') ?? item?.image" />
                <g-base-icon
                  v-else
                  class="text-light-450 flex-none"
                  width="36px"
                  height="36px"
                  view-box="0 0 24 24"
                  name="no-image" />
                <p class="text-12 text-light-100 font-regular line-clamp-2">
                  {{ (item as any)?.title }}
                </p>
                <GButtonV2
                  type="ghost"
                  class="text-light-450 ml-auto hidden h-32 w-32 flex-none items-center justify-center !p-0"
                  :class="{ 'group-hover:flex': list && list.length > 1 }"
                  @click="deleteSingleItem((item as any)?.id)">
                  <g-base-icon name="close-round" width="16" height="16" viewBox="0 0 16 16"></g-base-icon>
                </GButtonV2>
              </div>
            </template>
          </GReorder>
          <div
            v-if="
              (end < (cloneList?.length ?? 0) || end > FIRST_VIEW_ITEM) && (cloneList?.length ?? 0) > FIRST_VIEW_ITEM
            "
            class="grid grid-cols-2 gap-8">
            <GButtonV2
              type="secondary"
              icon-before="minus-gemx"
              icon-view-box="0 0 16 16"
              padding-x="8"
              size="medium"
              button-width="full"
              :disable="end <= FIRST_VIEW_ITEM"
              @click="prev()">
              Show less
            </GButtonV2>
            <GButtonV2
              type="secondary"
              icon-before="plus-gemui"
              icon-view-box="0 0 16 16"
              padding-x="8"
              button-width="full"
              size="medium"
              :disable="end >= (cloneList?.length ?? 0)"
              @click="next()">
              Show more
            </GButtonV2>
          </div>
        </div>
        <div v-else class="bg-dark-400 rounded-medium flex h-[48px] items-center gap-8 p-8">
          <div class="rounded-medium aspect-square h-32 w-32 shrink-0 overflow-hidden object-cover">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.10051 6.10051C11.5678 0.633164 20.4322 0.633164 25.8995 6.10051C31.3668 11.5678 31.3668 20.4322 25.8995 25.8995C20.4322 31.3668 11.5678 31.3668 6.10051 25.8995C0.633164 20.4322 0.633164 11.5678 6.10051 6.10051ZM23.7491 6.83666C19.0344 2.84053 11.9629 3.06655 7.51472 7.51472C3.06655 11.9629 2.84053 19.0344 6.83666 23.7491L23.7491 6.83666ZM8.25087 25.1633C12.9656 29.1595 20.0371 28.9335 24.4853 24.4853C28.9335 20.0371 29.1595 12.9656 25.1633 8.25087L8.25087 25.1633Z"
                fill="#757575" />
            </svg>
          </div>
          <p class="text-light-450 font-regular text-12 line-clamp-2 leading-[14px]">Product not found</p>
        </div>
        <div v-if="!isSalePage" class="flex">
          <GButtonV2
            type="tertiary"
            size="medium"
            button-width="full"
            icon-before="cursor-target"
            icon-view-box="0 0 16 16"
            icon-size="16"
            @click="openModal">
            Pick products
          </GButtonV2>
        </div>
        <div v-else class="mt-4 flex items-center">
          <span class="text-text-dark-300 text-12 font-regular leading-5"
            >You can change it in Funnel's
            <span class="text-primary-250 cursor-pointer" @click="openSaleFunnel">Trigger tab</span></span
          >
        </div>
      </div>
      <MultiProductModal
        :value="cloneList"
        :list="productList"
        :type="'Product'"
        :open="productListVisibility"
        :is-loading="isLoading"
        :max="max"
        :sync-percent="syncPercent"
        :page-type="pageType"
        :max-allow-selected="maxAllowSelected"
        :input-search-component="inputSearchComponent"
        :warning-message="warningMessage"
        @show-more="emit('showMore', 'fetch')"
        @refresh="emit('controlRefresh')"
        @close="closeModal"
        @set-value-selected="setValueSelected"
        @on-search="onSearch" />
    </div>
  </div>
</template>
