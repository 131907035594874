<script lang="ts" setup>
import { ref, onMounted, watch } from 'vue';
import Slider from './Slider.vue';
import type { ScreenUnit } from '../type/common';
import { NumberUnit } from '..';
import { computed } from 'vue';

type PropsType = {
  id: string;
  value?: string | number;
  min?: number;
  max?: number;
  ignoreMax?: boolean;
  step?: number;
  units?: string[];
  relatedValue?: string | number | null;
  minRelated?: string;
  maxRelated?: string;
  ignoreUnit?: boolean;
  disableOnChange?: boolean; // allow onchange event
  controlChange?: (id: string, value?: any) => void;
  hideUnit?: boolean;
  hideInput?: boolean;
  useOnlyUnitInit?: boolean;
};

const props = withDefaults(defineProps<PropsType>(), {
  id: '',
  min: 0,
  max: Infinity,
  value: '23',
  step: 1,
  disableOnChange: false,
  ignoreMax: false,
});

const emit = defineEmits<{
  (e: 'controlOnChange', controlId?: string, value?: any): void;
  (e: 'controlChange', controlId?: string, value?: any): void;
  (e: 'controlFocus', controlId?: string, value?: any): void;
  (e: 'controlBlur', controlId?: string, value?: any): void;
}>();

const sliderVal = ref<number>(parseFloat(props.value?.toString() ?? '0'));
const inputVal = ref<number | string>(parseFloat(props.value?.toString() ?? '0'));
const timeOut = ref();

const latestUnit = ref(props.units?.[0] || (getValueUnit(props.value.toString()) as ScreenUnit) || 'px');

const displayValue = computed(() => {
  return `${inputVal.value}${latestUnit.value}`;
});

function getValueUnit(value: string) {
  const regex = new RegExp(/(\d*\.?\d+)\s?(px|em|rem|ex|%|in|cn|mm|pt|pc+)/g);
  return regex.test(value) ? value.replace(/(-|\d)+/gi, '') : 'px';
}

const modifyRelatedValue = (value: number | string) => {
  if (!props.relatedValue && props.relatedValue?.toString() !== '0') {
    emit('controlChange', props.id, inputVal.value);
    return;
  }

  if (props.maxRelated && +props.relatedValue < +value) {
    inputVal.value = 0;
    sliderVal.value = 0;
    emit('controlChange', props.id, 0);
    emit('controlOnChange', props.id, 0);
    return;
  } else if (props.minRelated && +props.relatedValue > +value) {
    emit('controlChange', props.minRelated, 0);
    emit('controlOnChange', props.minRelated, 0);
  }

  emit('controlChange', props.id, inputVal.value);
  return;
};

const onChange = (value: number) => {
  clearTimeout(timeOut.value);
  if (props.disableOnChange) return;
  if (value < props.min) {
    sliderVal.value = props.min;
  } else if (value > props.max) {
    sliderVal.value = props.max;
  } else {
    sliderVal.value = value;
  }
  if (!props.useOnlyUnitInit) {
    inputVal.value = value + latestUnit.value;
  } else {
    inputVal.value = value;
  }

  timeOut.value = setTimeout(() => modifyRelatedValue(inputVal.value), 700);
};

watch(
  () => props.value,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      const value = parseFloat(newValue?.toString() ?? '0');
      sliderVal.value = value;
      inputVal.value = value;
    }
  },
);

onMounted(() => {
  const value = parseFloat(props.value?.toString() ?? '0');
  sliderVal.value = value;
  inputVal.value = value;
});

watch(
  () => props.value,
  () => {
    sliderVal.value = parseFloat(props.value?.toString() ?? '0');
    inputVal.value = parseFloat(props.value?.toString() ?? '0');
  },
);

const onInputChange = (value: string, type: string) => {
  if (value === undefined) {
    return;
  }
  const valueNotUnit = parseFloat(value?.toString() ?? '0');
  inputVal.value = valueNotUnit;
  if (valueNotUnit) {
    if (valueNotUnit < props.min) {
      sliderVal.value = props.min;
    } else if (valueNotUnit > props.max) {
      sliderVal.value = props.max;
    } else {
      sliderVal.value = valueNotUnit;
    }
  } else {
    sliderVal.value = props.min;
  }

  if (!props.useOnlyUnitInit) {
    inputVal.value = valueNotUnit + latestUnit.value;
    sliderVal.value = valueNotUnit;
  }
  if (type === 'change') {
    modifyRelatedValue(inputVal.value);
  } else emit('controlOnChange', props.id, inputVal.value);
};
</script>
<template>
  <div class="w-full">
    <slot name="label"></slot>
    <div class="gemx-range-slider flex items-center gap-8" data-test="editor-control-range">
      <div v-if="!hideInput" class="min-w-[48px]">
        <NumberUnit
          :id="id"
          :value="displayValue"
          :use-only-unit-init="useOnlyUnitInit"
          :units="units"
          :min="min"
          :max="ignoreMax ? undefined : max"
          :hide-unit="hideUnit"
          :input-class="hideUnit ? '!w-[48px] !px-0 text-center' : '!w-[62px]'"
          input-type="number"
          @change="(value) => onInputChange(value, 'change')"
          @on-change="(value) => onInputChange(value, 'onChange')" />
      </div>
      <div class="relative flex flex-1 items-center" :class="{ 'w-full': hideInput }">
        <Slider
          :min="min"
          :max="max"
          :ignore-max="ignoreMax"
          :step="step"
          :model-value="sliderVal"
          :full-width="hideInput"
          @update:model-value="onChange" />
      </div>
    </div>
    <slot name="info"></slot>
  </div>
</template>

<style lang="scss" scoped>
.gemx-range-slider {
  input {
    -webkit-appearance: none;
    width: 100%;
    background: #494949;
    outline: none;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }

  input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    background: #3c67ff;
    border-radius: 100%;
    cursor: pointer;
  }

  input::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: #3c67ff;
    border-radius: 100%;
    cursor: pointer;
  }
}
</style>
