<template>
  <div class="gemx-control">
    <slot name="label"></slot>
    <div class="gt_control-textarea group relative">
      <textarea
        ref="textarea"
        v-model="val"
        class="textarea scroll-bar !text-12 min-h-[36px]"
        :class="inputClass"
        type="text"
        :dir="direction"
        :rows="defaultRows || 2"
        :placeholder="placeholder || ''"
        :disabled="readonly"
        @input.stop="onChange"
        @change.stop="change"
        @focus.stop="focus"
        @blur.stop="blur" />
      <div
        v-if="showPlusBtn"
        class="group-hover:bg-underlay-dark-blue absolute right-4 top-4 flex h-28 w-28 cursor-pointer items-center justify-center rounded-xl"
        :class="toggleSuggest && 'bg-underlay-dark-blue'"
        @click.stop="openSuggest">
        <GBaseIcon name="plus-medium" width="16px" height="16px" class="text-light-200"></GBaseIcon>
      </div>
      <div
        v-if="suggestContents?.length && toggleSuggest"
        class="gt_control-textarea-suggest bg-dark-400 rounded-12 shadow-4dp absolute z-50 mt-4 flex w-full flex-col p-4 text-white">
        <div v-for="(item, index) in suggestContents" :key="index">
          <component
            :is="getComponentSuggest(!!item?.tooltip)"
            placement="right"
            class="w-full"
            :margin-left="-4"
            :is-teleport="true">
            <div
              class="hover:bg-dark-250 suggest-content group flex w-full cursor-pointer justify-between rounded-xl p-8"
              @click="handleClickSuggestItem(item.message)">
              <div class="flex flex-col gap-4">
                <div class="text-14">{{ item.message }}</div>
                <div class="text-12 text-text-dark-100">
                  E.g: <span :class="{ 'line-through': item.isStrike }">{{ item.eg }}</span>
                </div>
              </div>
              <span class="suggest-icon invisible h-[28px] w-[28px] p-6">
                <GBaseIcon name="plus-medium" width="16px" height="16px"></GBaseIcon>
              </span>
            </div>

            <template v-if="!!item.tooltip" #content>
              <div class="text-text-dark-500 text-12 z-50 w-[143px] whitespace-pre-line break-words">
                {{ item.tooltip || '' }}
              </div>
            </template>
          </component>
        </div>
      </div>
    </div>
    <slot name="info"></slot>
  </div>
</template>

<script lang="ts" setup>
import { stringInsertToIndex } from '@gem/control/src/helpers/common';
import { useOutsideClick } from '@gem/uikit';
import { onMounted, ref, watch, nextTick } from 'vue';
import { GTooltip } from '@gem/uikit';

type PropsType = {
  id?: string | number;
  placeholder?: string;
  value?: any;
  minHeight?: number;
  maxHeight?: number;
  readonly?: boolean;
  inputClass?: string;
  autoHeight?: boolean;
  defaultRows?: number;
  showPlusBtn?: boolean;
  suggestContents?: {
    message: string;
    eg?: string;
    isStrike?: boolean;
    tooltip?: string;
  }[];
  direction?: 'ltr' | 'rtl';
};
const props = withDefaults(defineProps<PropsType>(), {
  id: '',
  value: [],
  options: [
    {
      label: 'Unset',
      value: 'unset',
    },
  ],
  minHeight: 50,
  readonly: false,
  direction: 'ltr',
});
const textarea = ref<HTMLInputElement>();

const emit = defineEmits<{
  (e: 'controlOnChange', controlId?: string | number, value?: any): void;
  (e: 'controlChange', controlId?: string | number, value?: any): void;
  (e: 'controlFocus', controlId?: string | number, value?: any): void;
  (e: 'controlBlur', controlId?: string | number, value?: any): void;
  (e: 'clickDisabled'): void;
}>();

const val = ref(props.value);
const toggleSuggest = ref(false);

const getComponentSuggest = (hasTooltip: boolean) => (hasTooltip ? GTooltip : 'div');

watch(
  () => props.value,
  (value) => {
    setValue(value);
  },
);

watch(
  val,
  () => {
    nextTick(() => {
      if (props.autoHeight) {
        autosize();
      }
    });
  },
  { immediate: true },
);

onMounted(() => {
  setRangeHeight();
});

const setValue = (value: any) => {
  if (value != val.value) {
    val.value = value;
  }
};

const onChange = () => {
  if (props.readonly) {
    return;
  }
  emit('controlOnChange', props.id, val.value);
};

const change = () => {
  if (props.readonly) {
    return;
  }
  if (val.value) {
    val.value = val.value.trim();
    if (props.id === 'cssClass') {
      val.value = val.value.trim().replace(/(\r+\n+|\n+|\r+)/gm, ' ');
    }
    if (props.id === 'relatedExclude') {
      val.value = val.value
        .trim()
        .replace(/,?([\r\n|\n|\r|,]+),?/gm, ',')
        .replace(/(^,)|(,$)/g, '');
    }
  }
  emit('controlChange', props.id, val.value);
};

const focus = () => {
  toggleSuggest.value = false;
  emit('controlFocus', props.id, val.value);
};

const openSuggest = () => {
  toggleSuggest.value = true;
};

const blur = () => {
  emit('controlBlur', props.id, val.value);
};

const autosize = () => {
  nextTick(() => {
    if (textarea.value) {
      const textareaClone = textarea.value;
      if (textareaClone) {
        textareaClone.style.height = textareaClone?.scrollHeight ? `${textareaClone?.scrollHeight}px` : 'auto';
      }
    }
  });
};

const setRangeHeight = () => {
  const $textarea = textarea.value;
  if (!$textarea) return;
  if (props.maxHeight) {
    $textarea.style.maxHeight = props.maxHeight + 'px';
  }
  if (props.autoHeight) {
    autosize();
  } else {
    if (props.minHeight) {
      $textarea.style.minHeight = props.minHeight + 'px';
    }
  }
};

const handleClickOutSide = () => {
  toggleSuggest.value = false;
};

useOutsideClick(textarea, handleClickOutSide, {
  detectIframe: true,
  containSelectors: ['.gt_control-textarea-suggest'],
});

const handleClickSuggestItem = (suggestValue: string) => {
  const oldValue = val.value as string;
  const currentPointerIndex = textarea.value?.selectionStart || oldValue.length;
  const newValue = stringInsertToIndex(oldValue, ` ${suggestValue}`, currentPointerIndex);
  emit('controlChange', props.id, newValue);
};
</script>

<style lang="css" scoped>
.textarea {
  @apply bg-dark-400 hover:bg-dark-200 text-12 font-regular box-border block w-full resize-none overflow-hidden rounded-xl border border-transparent p-8 text-white outline-none;
  @apply placeholder:text-text-dark-100 focus:border-primary-250 hover:border-transparent;
  @apply disabled:bg-dark-200 disabled:text-text-dark-100 disabled:hover:border-dark-200 disabled:cursor-not-allowed disabled:select-none;
}

.scroll-bar::-webkit-scrollbar {
  width: 12px;
  background-color: transparent;
}

.scroll-bar::-webkit-scrollbar-thumb {
  background-color: #757575;
  border-radius: 8px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

.scroll-bar::-webkit-scrollbar-track {
  background: transparent;
  margin-top: 5px;
  margin-bottom: 5px;
}

.suggest-content:hover > .suggest-icon {
  visibility: visible;
}
</style>
